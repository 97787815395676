import styled from 'styled-components'
import { rgba } from 'polished'

import { media } from '~styles/mediaQuery'
import { fluidSpacingFunc } from '~styles/helpers'

const Wrapper = styled.section`
  display: grid;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => rgba(theme.palette.primary.main, 0.47)};
  ${fluidSpacingFunc([{ property: 'padding', values: [6, 0] }])};
  ${media.mq12`
    grid-template-areas:
      ". header header header ."
      ". titles-0 divider-0 services-0 ."
      ". titles-1 divider-1 services-1 .";
    grid-template-columns: 1fr minmax(min-content, max-content) ${({ theme }) =>
      theme.spacing[2]} minmax(min-content, max-content) 1fr;
    grid-gap: ${({ theme }) => theme.spacing[32]};
  `};
  ${media.mq34`
    grid-template-areas:
      ". header header header header header header header ."
      ". titles-0 divider-0 services-0 . titles-1 divider-1 services-1 .";
    grid-template-columns: 1fr minmax(min-content, max-content) ${({ theme }) =>
      theme
        .spacing[2]} minmax(min-content, max-content) 1fr minmax(min-content, max-content) ${({
    theme,
  }) => theme.spacing[2]} minmax(min-content, max-content) 1fr;
  `};
  grid-gap: ${({ theme }) => theme.spacing[64]}
    ${({ theme }) => theme.spacing[32]};
`

export default Wrapper
