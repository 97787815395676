import React, { Fragment } from 'react'

import { NAVIGATION } from '~utils/constants'

import { generateIdOutOfAnchorLink } from '../utils'

import messages from './messages'
import Wrapper from './Wrapper'
import Divider from './Divider'
import Headers from './Headers'
import Header from './Header'
import Title from './Title'
import Titles from './Titles'
import Service from './Service'
import Services from './Services'
import Img from './Img'
import IconSuccess from './IconSuccess'

const items = [
  {
    titles: [messages.item1Title1, messages.item1Title2],
    notes: [
      messages.item1Note1,
      messages.item1Note2,
      messages.item1Note3,
      messages.item1Note4,
      messages.item1Note5,
      messages.item1Note6,
    ],
    id: 1,
  },
  {
    titles: [messages.item2Title1, messages.item2Title2],
    notes: [
      messages.item2Note1,
      messages.item2Note2,
      messages.item2Note3,
      messages.item2Note4,
      messages.item2Note5,
      messages.item2Note6,
    ],
    id: 2,
  },
]

const myNewVersionImage = {
  alt: messages.myNewVersionImageAlt,
  name: 'Hiking_01',
  path: 'PagePrice',
  placeholder:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyOTE4IDM4OTEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1YjY3NzUiIGQ9Ik0wIDBoMjkxOHYzODkxSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjYgNy42KSBzY2FsZSgxNS4xOTkyMikiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMzODM0MzIiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMTIuMjE3ODUgNzQuMjY5MDYgLTE4MC4yNDMxMyAyOS42NTE0MyA2Ny4yIDIzMy41KSIvPjxlbGxpcHNlIGZpbGw9IiM3ODhkYTciIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTE5MC4zMzAxNCAtMTUuOTgyNDcgNy45Mjc1MSAtOTQuNDA2MjQgOTUgNDcuMikiLz48cGF0aCBmaWxsPSIjNWI1OTVjIiBkPSJNOTUgMTEybDU5IDE1NC04OC01MXoiLz48cGF0aCBmaWxsPSIjMWYzNTRkIiBkPSJNOSAxMzFsLTI1IDE5IDY1LTZ6Ii8+PGVsbGlwc2UgZmlsbD0iIzMzM2Y0YSIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSgtMTYuMiA4ODMuMyAzLjIpIHNjYWxlKDMyLjU3MjIyIDc5LjU4NzEyKSIvPjxwYXRoIGZpbGw9IiMzMjQwNGEiIGQ9Ik0xODYuMSAxODlsLTM0LjMtMjYgMTgtMjQgMzQuNCAyNnoiLz48cGF0aCBmaWxsPSIjNWM1YjVkIiBkPSJNMjA3IDIxOWwtNTktNjMtODctMjN6Ii8+PGVsbGlwc2UgZmlsbD0iIzY3Nzk4ZSIgY3g9IjE0MCIgY3k9IjEzOCIgcng9IjI4IiByeT0iMjAiLz48L2c+PC9zdmc+',
}

const MyNewVersionProgram = () => (
  <Wrapper id={generateIdOutOfAnchorLink(NAVIGATION.PRICE.NEW_VERSION)}>
    <Headers>
      <IconSuccess />
      <Header as="h2" type="header2" align="center" uppercase>
        {messages.header}
      </Header>
      <IconSuccess size="large" />
    </Headers>
    {items.map(({ titles, notes, id }, index) => (
      <Fragment key={id}>
        <Titles position={index}>
          {titles.map((title) => (
            <Title as="h3" type="header4" uppercase key={title}>
              {title}
            </Title>
          ))}
        </Titles>
        <Divider position={index} />
        <Services position={index}>
          {notes.map((note) => (
            <Service key={note}>{note}</Service>
          ))}
        </Services>
      </Fragment>
    ))}
    <Img imageData={myNewVersionImage} fit="cover" />
  </Wrapper>
)

export default MyNewVersionProgram
