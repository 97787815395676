import styled from 'styled-components'

import Typography from '~components/Typography'

const Service = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary.contrastText};
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing[12]};
  }
`

export default Service
