import styled from 'styled-components'

const Headers = styled.div`
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default Headers
