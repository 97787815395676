import styled from 'styled-components'

import { maxWidthStyle } from '~components/MaxWidthWrapper'
import { media } from '~styles/mediaQuery'

export const Links = styled.div`
  ${maxWidthStyle};
  width: 100%;
  display: flex;
  justify-content: center;
  ${media.mq1`
    flex-direction: column;
    display: none;
  `};
`

export const LinkWrapper = styled.div`
  ${media.mq1`
      margin-bottom: ${({ theme }) => theme.spacing[16]};
  `};
  ${media.mq234`
    margin-right: ${({ theme }) => theme.spacing[32]};
    &:last-child {
      margin-right: 0;
    }
  `};
`
