import { NAVIGATION } from '~utils/constants'

import { generateIdOutOfAnchorLink } from './utils'

export const messages = {
  header: 'Preise',
}

export const personalTraining = {
  header: 'Personal Training',
  anchor: generateIdOutOfAnchorLink(NAVIGATION.PRICE.PERSONAL_TRAINING),
  sections: [
    {
      header: 'Bei dir',
      prices: [
        {
          single: 'Einzeltraining 60min',
          singlePrice: '160 CHF',
          abo: '10er Abo a 60min',
          aboPrice: "1'500 CHF",
        },
        {
          single: 'Einzeltraining 40min',
          singlePrice: '120 CHF',
          abo: '10er Abo a 40min',
          aboPrice: "1'100 CHF",
        },
        {
          single: 'Partnertraining 60min',
          singlePrice: '100 CHF p.P.',
          abo: '10er Abo a 60min',
          aboPrice: '900 CHF p.P.',
        },
      ],
    },
    {
      header: 'Bei mir',
      prices: [
        {
          single: 'Einzeltraining 60min',
          singlePrice: '150 CHF',
          abo: '10er Abo a 60min',
          aboPrice: "1'400 CHF",
        },
        {
          single: 'Einzeltraining 40min',
          singlePrice: '110 CHF',
          abo: '10er Abo a 40min',
          aboPrice: "1'000 CHF",
        },
        {
          single: 'Partnertraining 60min',
          singlePrice: '90 CHF p.P.',
          abo: '10er Abo a 60min',
          aboPrice: '800 CHF p.P.',
        },
      ],
    },
  ],
}

export const nutrition = {
  header: 'Ernährung',
  anchor: generateIdOutOfAnchorLink(NAVIGATION.PRICE.NUTRITION),
  prices: [
    {
      header: 'Ernährungsberatung 60-90min',
      price: '160 CHF/h',
      description:
        'Individuelles Ernährungskonzept für deine persönlichen Ziele',
    },
    {
      header: 'Mehrfachberatung 8 Wochen',
      price: 'Auf Anfrage',
      description:
        'Individuelles Ernährungskonzept Jede 2. Woche Besprechung und Anpassung',
    },
  ],
}

export const other = {
  header: 'Weiteres',
  anchor: generateIdOutOfAnchorLink(NAVIGATION.PRICE.OTHER),
  prices: [
    {
      header: 'Trainingsplan / Online Coaching',
      price: 'Auf Anfrage',
      description:
        'Dein individueller Trainingsplan für Zuhause, das Gym, einen Wettkampf oder ein anderes sportliches Ziel',
    },
    {
      header: 'Für Unternehmen',
      price: 'Auf Anfrage',
      description:
        'Firmen Fitness, Veranstaltungen und Seminare über Ernährung, Bewegung und Gesundheit.',
    },
  ],
}
