import styled from 'styled-components'

import { limitMaxWidth } from '~styles/constants'
import { maxWidthStyle } from '~components/MaxWidthWrapper'
import { media } from '~styles/mediaQuery'

const Grid = styled.div`
  ${maxWidthStyle};
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  ${media.mq1`
    grid-template-columns: 1fr;
    max-width: ${limitMaxWidth.mq3};
    grid-gap: ${({ theme }) => theme.spacing[16]};
  `};
  ${media.mq234`
    grid-template-columns: 1fr 1fr;
    grid-gap: ${({ theme }) => theme.spacing[32]};
  `};
`

export const GridPersonalTraining = styled(Grid)`
  ${media.mq1`
    grid-template-areas:
    'sectionHeader'
    'subHeader-0 '
    'card-00'
    'card-01'
    'card-02'
    'subHeader-1'
    'card-10'
    'card-11'
    'card-12';
  `};
  ${media.mq234`
    grid-template-areas:
    'sectionHeader sectionHeader'
    'subHeader-0 subHeader-1'
    'card-00 card-10'
    'card-01 card-11'
    'card-02 card-12';
  `};
`

export const Grid2Columns = styled(Grid)`
  ${media.mq1`
    grid-template-areas:
    'sectionHeader'
    'card-0'
    'card-1';
  `};
  ${media.mq234`
    grid-template-areas:
    'sectionHeader sectionHeader'
    'card-0 card-1';
  `};
`
