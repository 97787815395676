import styled from 'styled-components'

import Typography from '~components/Typography'
import { media } from '~styles/mediaQuery'

export const SectionHeader = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  grid-area: sectionHeader;
`

SectionHeader.defaultProps = {
  uppercase: true,
  limit: true,
  align: 'center',
  as: 'h2',
  type: 'header2',
}

export const SubHeader = styled(Typography)`
  grid-area: ${({ position }) => `subHeader-${position}`};
  ${media.mq1`
    margin-top: ${({ theme, position }) =>
      position !== 0 ? theme.spacing[16] : 0};
  `};
`
