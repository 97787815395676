import React from 'react'

import Typography from '~components/Typography'

import { SectionHeader } from './Header'
import { Grid2Columns } from './Grid'
import { Card, CardHeader, CardPrice } from './Card'

const NutritionAndOther = ({ data }) => (
  <Grid2Columns>
    <SectionHeader id={data.anchor}>{data.header}</SectionHeader>
    {data.prices.map(({ header, description, price }, i) => (
      <Card key={description} position={i}>
        <CardHeader align="center">{header}</CardHeader>
        <CardPrice align="center">{price}</CardPrice>
        <Typography>{description}</Typography>
      </Card>
    ))}
  </Grid2Columns>
)

export { NutritionAndOther }
