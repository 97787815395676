import styled from 'styled-components'

import Typography from '~components/Typography'

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing[12]};
  }
`

export default Title
