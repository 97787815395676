const messages = {
  header: 'My new Version Program',
  myNewVersionImageAlt: 'Frau auf einem Berg, die die Arme in die Luft streckt',
  item1Title1: '8 Wochen',
  item1Title2: '1520 CHF',
  item1Note1: '8x personal Training',
  item1Note2: 'Ernährungsanalyse',
  item1Note3: 'Ernährungsplan',
  item1Note4: 'Wöchentliche Messung',
  item1Note5: 'Trainingsplan',
  item1Note6: 'Online Coaching',
  item2Title1: '12 Wochen',
  item2Title2: '2220 CHF',
  item2Note1: '12x personal Training',
  item2Note2: 'Ernährungsanalyse',
  item2Note3: 'Ernährungsplan',
  item2Note4: 'Wöchentliche Messung',
  item2Note5: 'Trainingsplan',
  item2Note6: 'Online Coaching',
}

export default messages
