import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import Typography from '~components/Typography'
import { NAVIGATION } from '~utils/constants'

import { personalTraining, nutrition, other } from '../messages'

import { Links, LinkWrapper } from './styles'

const anchorLinks = [
  {
    title: personalTraining.header,
    link: NAVIGATION.PRICE.PERSONAL_TRAINING,
  },
  {
    title: nutrition.header,
    link: NAVIGATION.PRICE.NUTRITION,
  },
  {
    title: 'New Version',
    link: NAVIGATION.PRICE.NEW_VERSION,
  },
  {
    title: other.header,
    link: NAVIGATION.PRICE.OTHER,
  },
]

const AnchorLinks = () => (
  <Links>
    {anchorLinks.map(({ title, link }) => (
      <LinkWrapper key={link}>
        <Typography as={AnchorLink} stripHash to={link}>
          {title}
        </Typography>
      </LinkWrapper>
    ))}
  </Links>
)

export default AnchorLinks
