import styled from 'styled-components'

import Typography from '~components/Typography'
import { fluidSpacingFunc } from '~styles/helpers'

const Header = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary.contrastText};
  ${fluidSpacingFunc([{ property: 'margin', values: [0, 6] }])};
`

export default Header
