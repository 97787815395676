import styled from 'styled-components'

import Typography from '~components/Typography'
import { shadows } from '~styles/constants'
import { fluidSpacingFunc } from '~styles/helpers'

export const Card = styled.div`
  ${fluidSpacingFunc([{ property: 'padding', values: [2] }])};
  display: flex;
  flex-direction: column;
  box-shadow: ${shadows[1]};
  border-radius: ${({ theme }) => theme.spacing[2]};
  grid-area: ${({ position }) => `card-${position}`};
`

export const CardHeader = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`

export const CardPrice = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing[32]};
`
