import React, { Fragment } from 'react'

import Typography from '~components/Typography'

import { SectionHeader, SubHeader } from './Header'
import { Card, CardPrice, CardHeader } from './Card'
import { GridPersonalTraining } from './Grid'

const PersonalTraining = ({ data }) => (
  <GridPersonalTraining>
    <SectionHeader id={data.anchor}>{data.header}</SectionHeader>
    {data.sections.map(({ header, prices }, i) => (
      <Fragment key={header}>
        <SubHeader uppercase align="center" as="h3" type="header3" position={i}>
          {header}
        </SubHeader>
        {prices.map(({ single, singlePrice, abo, aboPrice }, j) => (
          <Card key={`${single}${singlePrice}`} position={`${i}${j}}`}>
            <CardHeader align="center">{single}</CardHeader>
            <CardPrice align="center">{singlePrice}</CardPrice>
            <CardHeader align="center">{abo}</CardHeader>
            <Typography align="center">{aboPrice}</Typography>
          </Card>
        ))}
      </Fragment>
    ))}
  </GridPersonalTraining>
)

export { PersonalTraining }
