import React from 'react'
import PropTypes from 'prop-types'

import Layout from '~components/Layout'
import { NAVIGATION } from '~utils/constants'

import Content from './Content'
import MyNewVersionProgram from './MyNewVersionProgram'
import AnchorLinks from './AnchorLinks'
import { NutritionAndOther, PersonalTraining } from './Grids'
import { messages, personalTraining, nutrition, other } from './messages'

const metaTags = {
  title: 'Preise Personal Training und Coaching in Zürich',
  description:
    'Jetzt mit deinem Personal Training starten und diene Ziele effizient erreichen. Hier findest du die Preise für deine Trainings in Zürich.',
  slug: NAVIGATION.PRICE.PRICE,
  image: {
    url: 'PagePrice/HeroPrice_01--large',
    alt: 'Eine Person, die auf einer nassen Strassen rennt',
  },
}

const hero = {
  pictureData: {
    path: 'PagePrice',
    alt: 'Eine Person, die auf einer nassen Strassen rennt',
    fallback: {
      name: 'HeroPrice_01--large',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOTUwIDIyMjIiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM3NDc1NzYiIGQ9Ik0wIDBoMzk1MHYyMjIxSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjcgNy43KSBzY2FsZSgxNS40Mjk2OSkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMwZDEwMTEiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTIuMDgzODYgMjAuMTY1MSAtMTM1LjU3MzM1IC0xNC4wMTAxNCAxNzEuNCA0Ni44KSIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xNjMuMyAyNC43bC0zLTI4LjggMTIwLjQtMTIuNiAzIDI4Ljh6Ii8+PGVsbGlwc2UgZmlsbD0iI2MxYzNjNCIgY3g9IjE2NiIgY3k9Ijk3IiByeD0iMTExIiByeT0iMzEiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC01MS4yNDE0NCAtOC4yNTI1NiAxLjk2MTE3IC0xMi4xNzcyIDU3LjggNikiLz48ZWxsaXBzZSBmaWxsPSIjMDcwYTBjIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDI3LjUyNzUzIDEwLjM0NjkyIC0xNy4yNTYyNiA0NS45MDk1NCAxMy41IDEwNC4zKSIvPjxlbGxpcHNlIGZpbGw9IiMxYzFlMWYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMjYuMDM3NjQgMy41NDQzMiAtMTEuNDI3MDkgODMuOTQ2OTIgMTM3LjYgMCkiLz48ZWxsaXBzZSBmaWxsPSIjMTQxNzE4IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC05NS42IDExNiAuNSkgc2NhbGUoNTkuOTkyMzYgNS4zNjU5NikiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiBjeD0iMjQwIiBjeT0iNSIgcng9Ijc4IiByeT0iMTAiLz48L2c+PC9zdmc+',
    },
    breakpoints: {
      breakpointSmall: {
        name: 'HeroPrice_01--small',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxODA5IDI3MTMiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM3NDc1NzYiIGQ9Ik0wIDBoMTgxMnYyNzEzSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjMgNS4zKSBzY2FsZSgxMC41OTc2NikiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMwMTA0MDUiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoMTAyLjYgMjIuMyA2My4xKSBzY2FsZSg2OS4zNzk2NyAzOS43MDk0MikiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC0xNTguNCA3OC40IC0xMS42KSBzY2FsZSgyMC43OTAyNyA0NC4wNTk2MikiLz48ZWxsaXBzZSBmaWxsPSIjYzBjMmMzIiBjeD0iMTE4IiBjeT0iMTU1IiByeD0iMTU3IiByeT0iNDYiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTkgNDkuNUwtNC45IDUyLTEzLTI1LjUgMTAuOS0yOHoiLz48ZWxsaXBzZSBmaWxsPSIjMGUxMTEyIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDE0LjQwOTggLTIuNjU4NzEgMTUuMjE5MTcgODIuNDg1NDQgODguOSAyMzYuMikiLz48ZWxsaXBzZSBmaWxsPSIjMDUwODBhIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC0yNC43IDI2MS41IC0zMTcuMSkgc2NhbGUoMzYuMDc2NjcgMjguODUxODIpIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTI4IDE3aDI2djI1SDI4eiIvPjxlbGxpcHNlIGZpbGw9IiMyMDIzMjUiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTE4LjAxMDYgLTI0LjMyNTU1IDMzLjU2MjcgLTI0Ljg0OTc4IDE5LjcgODEuOSkiLz48L2c+PC9zdmc+',
      },
      breakpointMedium: {
        name: 'HeroPrice_01--medium',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNjE0IDI2MTQiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM3YzdlN2UiIGQ9Ik0wIDBoMjYxNHYyNjE0SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjEgNS4xKSBzY2FsZSgxMC4yMTA5NCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMxMTEzMTUiIGN4PSIxNDUiIGN5PSI2NSIgcng9IjYyIiByeT0iNjIiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDczLjIgOTcgMTYyLjQpIHNjYWxlKDI2LjI3ODc1IDQ1LjY5NDUzKSIvPjxlbGxpcHNlIGZpbGw9IiNmZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoMTA1LjEgNSAxOC44KSBzY2FsZSgyNS43NTIzNSA4Ny4xODg0NykiLz48ZWxsaXBzZSBmaWxsPSIjZDhkOWRiIiBjeD0iMjAzIiBjeT0iMTUzIiByeD0iNjgiIHJ5PSIzOSIvPjxlbGxpcHNlIGZpbGw9IiMwNDA3MDkiIGN4PSIyMzgiIGN5PSI4OSIgcng9IjYzIiByeT0iMzAiLz48ZWxsaXBzZSBmaWxsPSIjMzIzNDM2IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC04MC42ODQ5MyA0OC44NDI1NCAtMjQuNjIxNTIgLTQwLjY3MzI3IDE4IDEwMikiLz48ZWxsaXBzZSBmaWxsPSIjY2VkMGQyIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC00OC43NzcxNyAyMi41MzU5OCAtMTIuOTI3MSAtMjcuOTc5NTggNzMuOSAxNTgpIi8+PGVsbGlwc2UgZmlsbD0iIzA1MDcwOSIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgxNy44Mzc4IC01LjU0NDgzIDEwLjQ0Nzg0IDMzLjYxMDg1IDE0Mi45IDIzMi40KSIvPjwvZz48L3N2Zz4=',
      },
      breakpointLarge: {
        name: 'HeroPrice_01--large',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOTUwIDIyMjIiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM3NDc1NzYiIGQ9Ik0wIDBoMzk1MHYyMjIxSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjcgNy43KSBzY2FsZSgxNS40Mjk2OSkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMwZDEwMTEiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTIuMDgzODYgMjAuMTY1MSAtMTM1LjU3MzM1IC0xNC4wMTAxNCAxNzEuNCA0Ni44KSIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xNjMuMyAyNC43bC0zLTI4LjggMTIwLjQtMTIuNiAzIDI4Ljh6Ii8+PGVsbGlwc2UgZmlsbD0iI2MxYzNjNCIgY3g9IjE2NiIgY3k9Ijk3IiByeD0iMTExIiByeT0iMzEiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC01MS4yNDE0NCAtOC4yNTI1NiAxLjk2MTE3IC0xMi4xNzcyIDU3LjggNikiLz48ZWxsaXBzZSBmaWxsPSIjMDcwYTBjIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDI3LjUyNzUzIDEwLjM0NjkyIC0xNy4yNTYyNiA0NS45MDk1NCAxMy41IDEwNC4zKSIvPjxlbGxpcHNlIGZpbGw9IiMxYzFlMWYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoMjYuMDM3NjQgMy41NDQzMiAtMTEuNDI3MDkgODMuOTQ2OTIgMTM3LjYgMCkiLz48ZWxsaXBzZSBmaWxsPSIjMTQxNzE4IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC05NS42IDExNiAuNSkgc2NhbGUoNTkuOTkyMzYgNS4zNjU5NikiLz48ZWxsaXBzZSBmaWxsPSIjZmZmIiBjeD0iMjQwIiBjeT0iNSIgcng9Ijc4IiByeT0iMTAiLz48L2c+PC9zdmc+',
      },
    },
  },
  textData: {
    header: messages.header,
  },
}

const PagePrice = ({ location }) => (
  <Layout
    hero={hero}
    pictureData={hero.pictureData}
    metaTags={metaTags}
    pathname={location.pathname}
  >
    <Content>
      <AnchorLinks />
      <PersonalTraining data={personalTraining} />
      <NutritionAndOther data={nutrition} />
      <MyNewVersionProgram />
      <NutritionAndOther data={other} />
    </Content>
  </Layout>
)

PagePrice.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PagePrice
