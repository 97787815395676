import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { icon } from '~styles/constants'

const Svg = styled.svg`
  fill: ${({ theme }) => theme.palette.secondary.main};
  ${media.mq1`
    display: none;
  `};
  ${media.mq2`
    width: ${icon.size.medium}px;
  `};
  ${media.mq34`
    width: ${icon.size.large}px;
  `};
`

export default Svg
