import styled from 'styled-components'

const Divider = styled.div`
  background: ${({ theme }) => theme.palette.secondary.main};
  grid-area: ${({ position }) => `divider-${position}`};
  height: 60%;
  width: ${({ theme }) => theme.spacing[2]};
`

export default Divider
